import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="home-page" style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 190px)',
      padding: '20px',
      boxSizing: 'border-box'
    }}>
    </div>
  );
};

export default HomePage;